<template lang="pug">
.home-page
  v-carousel.banner(
    cycle,
    hideDelimiters,
    showArrowsOnHover,
    v-model="slide",
    data-aos="fade-down",
    :height="$vuetify.breakpoint.xs ? '350' : '650'"
  )
    v-carousel-item(v-for="slider in sliderList", :key="slider", :src="slider")
  section.about.pa-5.pa-sm-10.pa-md-16
    v-row
      v-col.d-flex.justify-center.align-center(
        :cols="12",
        :sm="6",
        data-aos="fade-right"
      )
        div
          h1 ABOUT US
          p.description BRISK SERVICES - Your Trusted Business Partner in the Time Essential general marine ship chandlery and services last-mile fulfillment marketplace.
          v-btn(
            dark,
            x-large,
            color="#A1887F",
            @click="$router.push('/about')"
          ) MORE
      v-col.d-flex.justify-center.align-center(
        :cols="12",
        :sm="6",
        data-aos="flip-left"
      )
        v-img(contain, maxHeight="300px", :src="about1", :lazySrc="about1")
  section.construction-process.pa-5.pa-sm-10.pa-md-16
    Title(title="Core Services")
    v-row
      v-col.pa-md-10(
        v-for="constructionProcess in constructionProcessList",
        :key="constructionProcess.title",
        :cols="12",
        :sm="4",
        :data-aos="constructionProcess.aos",
        data-aos-anchor-placement="center-bottom"
      )
        v-card
          v-img(
            :src="constructionProcess.imgUrl",
            :lazySrc="constructionProcess.imgUrl",
            :aspectRatio="5 / 4"
          )
          .text-center.text-h5.py-3.orange.darken-1.white--text {{ constructionProcess.title }}
    .text-center.mt-10
      v-btn(
        dark,
        large,
        color="#A1887F",
        @click="$router.push('/assemblyProcess')"
      ) MORE
  section.actual-case.pa-5.pa-sm-10.pa-md-16
    Title(title="What We Do")
    v-row
      v-col.pa-md-10(
        v-for="(actualCase, index) in actualCaseList",
        :key="index",
        :cols="12",
        :sm="4",
        :data-aos="actualCase.aos",
        data-aos-anchor-placement="center-bottom"
      )
        v-card
          v-img(
            :src="actualCase.imgUrl",
            :lazySrc="actualCase.imgUrl",
            :aspectRatio="5 / 4"
          )
          .text-center.text-h5.py-3.orange.darken-1.white--text {{ actualCase.title }}
    .text-center.mt-10
      v-btn(dark, large, color="#A1887F", @click="$router.push('/actualCase')") MORE
</template>

<script>
import Title from "@/components/Title.vue";

// import banner1 from "@/assets/home/banner-1.jpg";
// import banner2 from "@/assets/home/banner-2.jpg";
// import banner3 from "@/assets/home/banner-3.jpg";
// import banner4 from "@/assets/home/banner-4.jpg";
// import about1 from "@/assets/home/about-1.png";
// import assembly1 from "@/assets/assemblyProcess/assembly-1.jpg";
// import assembly2 from "@/assets/assemblyProcess/assembly-2.jpg";
// import assembly3 from "@/assets/assemblyProcess/assembly-3.jpg";
// import actualCase8 from "@/assets/actualCase/actualCase-8.jpg";
// import actualCase17 from "@/assets/actualCase/actualCase-17.jpg";
// import actualCase21 from "@/assets/actualCase/actualCase-21.jpg";

// --- Brisk

import banner1 from "@/assets/brisk/banner-1.jpg";
import banner2 from "@/assets/brisk/banner-2.jpg";
import banner3 from "@/assets/brisk/banner-3.jpg";
import banner4 from "@/assets/brisk/banner-4.jpg";
import about1 from "@/assets/brisk/about-1.jpg";
import assembly1 from "@/assets/brisk/assembly-1.jpg";
import assembly2 from "@/assets/brisk/assembly-2.jpg";
import assembly3 from "@/assets/brisk/assembly-3.jpg";
import actualCase8 from "@/assets/brisk/actualCase-8.jpg";
import actualCase17 from "@/assets/brisk/actualCase-17.jpg";
import actualCase21 from "@/assets/brisk/actualCase-21.jpg";

export default {
  components: {
    Title,
  },
  data() {
    return {
      about1,
      slide: 0,
    };
  },
  computed: {
    sliderList: {
      get: () => [banner1, banner2, banner3, banner4],
    },
    constructionProcessList: {
      get: () => [
        {
          title: "Marine Stores",
          imgUrl: assembly2,
          aos: "fade-left",
        },
        {
          title: "On Board Repair",
          imgUrl: assembly1,
          aos: "fade-down",
        },
        {
          title: "Dock Works",
          imgUrl: assembly3,
          aos: "fade-right",
        },
      ],
    },
    actualCaseList: {
      get: () => [
        {
          title: "",
          imgUrl: actualCase17,
          aos: "fade-left",
        },
        {
          title: "",
          imgUrl: actualCase8,
          aos: "fade-up",
        },
        {
          title: "",
          imgUrl: actualCase21,
          aos: "fade-right",
        },
      ],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mediaQuery.scss";

.home-page::v-deep {
  .about {
    background-color: #b0bec5;

    .description {
      font-size: 1.2rem;
      line-height: 2.5rem;

      @include MQ(tablet) {
        line-height: 2rem;
      }
    }
  }
}
</style>
